import {NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterModule} from "@angular/router";
import {routes} from "./authenticate.routing";
import { AuthenticateComponent } from './authenticate.component';

@NgModule({
  declarations: [
    AuthenticateComponent,
  ],
  exports: [],
  imports: [
    NgxSpinnerModule,
    RouterModule.forChild(routes),
    CommonModule,

  ]
})
export class AuthenticateModule {
}
