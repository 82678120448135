import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private orderDataSource = new ReplaySubject<any>(1);
  order$ = this.orderDataSource.asObservable();

  updateOrder(order: any) {
    this.orderDataSource.next(order);
  }

  getOrder() {
    let orderValue: any;
    this.order$.subscribe(value => orderValue = value).unsubscribe();
    return orderValue;
  }
}
