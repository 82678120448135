import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../core/base.component";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService
  ) {
    super(router, translate);
    // translate.addLangs(['en-US', 'es-ES', 'pt-BR']);
    // super.handlerCultureLanguage(this.language);
  }

  ngOnInit(): void {
    super.handlerCultureLanguage(this.language);
  }

}
