<a href="javascript:void(0)" [matMenuTriggerFor]="languageMenu" class="mat-body-1 text-secondary">
  <div fxLayout="row nowrap">
    <div fxLayout="column" fxLayoutAlign="start center">
      <span style="margin-bottom: -7px;">{{language}}</span>
      <mat-icon>expand_more</mat-icon>
    </div>
    <img class="language_img ml-5" src="assets/images/{{language}}.png" alt="language">
  </div>
</a>
<mat-menu #languageMenu="matMenu">
  <button mat-menu-item (click)="handlerCultureLanguage('pt-BR')">
    PT
  </button>
  <button mat-menu-item (click)="handlerCultureLanguage('en-US')">
    EN
  </button>
  <button mat-menu-item (click)="handlerCultureLanguage('es-ES')">
    ES
  </button>
</mat-menu>
