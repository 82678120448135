import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/pt-br';

@Injectable({
  providedIn: 'root'
})
export class MomentConfigService {
  constructor() {
    moment.locale('pt-br');
  }
}
