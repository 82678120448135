<ngx-spinner
bdColor="rgba(22,42,112,1)"
size="medium"
color="#fff"
type="ball-spin"
>
<div>
  <p class="text-white text-center">Autenticando ...</p>

  <p class="text-white text-center" style="font-size: 14px" *ngIf="token">TOKEN:<br/><br/> {{token}}</p>

  <p class="text-white text-center" style="font-size: 14px" *ngIf="error">Error: <br/><br/> {{error}}</p>
</div>
</ngx-spinner>

