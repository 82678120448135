import {Injectable} from "@angular/core";

import {Observable, of, BehaviorSubject, switchMap} from "rxjs";
import {AuthenticateGQL, TokenVm} from "src/generated/graphql";
import {AuthUtils} from "./auth.utils";


@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private userDataSource = new BehaviorSubject<TokenVm>(new TokenVm());
  user = this.userDataSource.asObservable();

  constructor(
    // private currentUserGQL: CurrentUserGQL,
    private authenticateGQL: AuthenticateGQL) {
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  signIn(credentials: { code: string; redirectUri: string; }) {
    return this.authenticateGQL.mutate({
      code: credentials.code,
      redirectUri: credentials.redirectUri
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      const objUser = {
        name: data?.authenticate?.name,
        photo: data?.authenticate?.photo,
        email: data?.authenticate?.email,
        companyName: data?.authenticate?.companyName,
        chapaDeliveryMan: data?.authenticate?.chapaDeliveryMan,
      }
      localStorage.setItem('user', JSON.stringify(objUser));
      this.accessToken = data?.authenticate?.authorizationToken as string;
      // this.currentUser();
      return of(data);
    }));
  }

  getUser(): string {
    return JSON.parse(localStorage.getItem('user') ?? '');
  }

  signInUsingToken(): Observable<any> {
    this.currentUser();
    return of(true);
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    return of(true);
  }

  check(): Observable<boolean> {

    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    return this.signInUsingToken();
    // return of(true);
  }

  updateUser(user: TokenVm) {
    this.userDataSource.next(user);

  }

  currentUser() {
    return this.userDataSource.value;
    /*this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
      this.updateUser(data.currentUser as UserVm);
    }, () => {
      this._authenticated = false;
    });*/
  }

}
