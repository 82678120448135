import { NgModule } from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticateComponent} from "./modules/authenticate/authenticate.component";
import {AuthGuard} from "./core/auth/guards/auth.guard";

// Extra Config
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./core/login/login.module').then(m => m.LoginModule)},
  { path: "authenticate", component: AuthenticateComponent },
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./layout/main/main.module').then(m => m.MainModule),
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
