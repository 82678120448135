import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export class CollaboratorVm {
  chapa?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
};

export class DefaultMessage {
  message?: Maybe<Scalars['String']>;
  statusCode: Scalars['Int'];
};

export class DeliveryManVm {
  chapa?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
};

export class DropDownVm {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export class Input_AuthenticationVm {
  code?: InputMaybe<Scalars['String']>;
  redirectUri?: InputMaybe<Scalars['String']>;
};

export class Input_DiagramNetwork {
  diagram?: InputMaybe<Scalars['String']>;
  elementPep?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  projectDefinition?: InputMaybe<Scalars['String']>;
};

export class Input_LaunchMemorizedRequestVm {
  action?: InputMaybe<Scalars['String']>;
  deliveryMan?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  period?: Scalars['Int'];
};

export class Input_MaterialRequestVm {
  keyword?: InputMaybe<Scalars['String']>;
};

export class Input_Materials {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  quantity?: Scalars['Int'];
};

export class Input_RequestVm {
  chapaDeliveryMan?: InputMaybe<Scalars['String']>;
  chapaRequester?: InputMaybe<Scalars['String']>;
  costCenter?: InputMaybe<Scalars['String']>;
  diagramNetwork?: InputMaybe<Input_DiagramNetwork>;
  materials?: InputMaybe<Array<InputMaybe<Input_Materials>>>;
  nameDeliveryMan?: InputMaybe<Scalars['String']>;
  numberReservation?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  orderInternal?: InputMaybe<Scalars['String']>;
  photoDeliveryMan?: InputMaybe<Scalars['String']>;
  requester?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class LaunchMemorizedCountVm {
  total: Scalars['Int'];
};

export class LaunchMemorizedPaginatorVm {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<LaunchMemorizedVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class LaunchMemorizedVm {
  action?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  deliveryman?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  requester?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export class MaterialResponseVm {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export class MaterialResumeVm {
  batch?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shelf?: Maybe<Array<Maybe<MaterialShelf>>>;
  type?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export class MaterialShelf {
  batch?: Maybe<Scalars['String']>;
  centerDistribution?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  total: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
  validate?: Maybe<Scalars['String']>;
};

export class Mutation {
  /** Login User */
  authenticate?: Maybe<TokenVm>;
  saveRequester?: Maybe<DefaultMessage>;
};


export class MutationAuthenticateArgs {
  input?: InputMaybe<Input_AuthenticationVm>;
};


export class MutationSaveRequesterArgs {
  input?: InputMaybe<Input_RequestVm>;
};

export class Query {
  /** List Costs */
  dropDownCostCenter?: Maybe<Array<Maybe<DropDownVm>>>;
  /** List Definitions Project */
  dropDownDefinitionProject?: Maybe<Array<Maybe<DropDownVm>>>;
  /** List Diagram Network */
  dropDownDiagramNetwork?: Maybe<Array<Maybe<DropDownVm>>>;
  /** List Element Pep */
  dropDownElementPep?: Maybe<Array<Maybe<DropDownVm>>>;
  /** List Operation */
  dropDownOperation?: Maybe<Array<Maybe<DropDownVm>>>;
  findDeliveryman?: Maybe<Array<Maybe<DeliveryManVm>>>;
  findReserveOrder?: Maybe<Array<Maybe<MaterialResumeVm>>>;
  /** Count Launch Memorized */
  launchMemorizedCount?: Maybe<LaunchMemorizedCountVm>;
  /** Find Launch Memorized */
  launchMemorizedFind?: Maybe<LaunchMemorizedPaginatorVm>;
  /** Return Material */
  materialFind?: Maybe<Array<Maybe<MaterialResponseVm>>>;
  /** Return Material Resume by Code */
  materialResumeByCode?: Maybe<MaterialResumeVm>;
  /** Return Order Internal */
  orderInternal?: Maybe<Array<Maybe<DropDownVm>>>;
  /** Return Order Internal */
  orderInternalMaintenance?: Maybe<Array<Maybe<DropDownVm>>>;
  readQrCode?: Maybe<CollaboratorVm>;
  /** Find Stock */
  stockFind?: Maybe<Array<Maybe<StockResponseVm>>>;
};


export class QueryDropDownCostCenterArgs {
  keyword?: InputMaybe<Scalars['String']>;
};


export class QueryDropDownDiagramNetworkArgs {
  pepId?: InputMaybe<Scalars['String']>;
};


export class QueryDropDownElementPepArgs {
  projectId?: InputMaybe<Scalars['String']>;
};


export class QueryDropDownOperationArgs {
  diagramId?: InputMaybe<Scalars['String']>;
};


export class QueryFindReserveOrderArgs {
  code?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export class QueryLaunchMemorizedFindArgs {
  input?: InputMaybe<Input_LaunchMemorizedRequestVm>;
};


export class QueryMaterialFindArgs {
  input?: InputMaybe<Input_MaterialRequestVm>;
};


export class QueryMaterialResumeByCodeArgs {
  code?: InputMaybe<Scalars['String']>;
};


export class QueryOrderInternalArgs {
  costCenterId?: InputMaybe<Scalars['String']>;
};


export class QueryReadQrCodeArgs {
  code?: InputMaybe<Scalars['String']>;
};


export class QueryStockFindArgs {
  keyword?: InputMaybe<Scalars['String']>;
};

export class Shelf {
  batch?: Maybe<Scalars['String']>;
  centerDistribution?: Maybe<Scalars['String']>;
  deposit?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  validate?: Maybe<Scalars['String']>;
};

export class StockResponseVm {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shelf?: Maybe<Array<Maybe<Shelf>>>;
};

export class TokenVm {
  authorizationToken?: Maybe<Scalars['String']>;
  chapaDeliveryMan?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type DefaultMessageFragment = { statusCode: number, message?: string | null };

export type TokenVmFragment = { authorizationToken?: string | null, refreshToken?: string | null, name?: string | null, photo?: string | null, email?: string | null, companyName?: string | null, chapaDeliveryMan?: string | null };

export type StockResponseVmFragment = { id?: string | null, code?: string | null, description?: string | null, shelf?: Array<{ position?: string | null, validate?: string | null, quantity?: string | null, batch?: string | null, unit?: string | null, centerDistribution?: string | null, deposit?: string | null } | null> | null };

export type ShelfFragment = { position?: string | null, validate?: string | null, quantity?: string | null, batch?: string | null, unit?: string | null, centerDistribution?: string | null, deposit?: string | null };

export type LaunchMemorizedVmFragment = { id?: string | null, deliveryman?: string | null, type?: string | null, requester?: string | null, date?: string | null, action?: string | null, quantity: number };

export type LaunchMemorizedPaginatorVmFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: string | null, deliveryman?: string | null, type?: string | null, requester?: string | null, date?: string | null, action?: string | null, quantity: number } | null> | null };

export type MaterialResponseVmFragment = { code?: string | null, description?: string | null };

export type MaterialResumeVmFragment = { code?: string | null, description?: string | null, type?: string | null, deposit?: string | null, shelf?: Array<{ position?: string | null, total: number, validate?: string | null, quantity: number, batch?: string | null, centerDistribution?: string | null, deposit?: string | null } | null> | null };

export type MaterialShelfFragment = { position?: string | null, total: number, validate?: string | null, quantity: number, batch?: string | null, centerDistribution?: string | null, deposit?: string | null };

export type DropDownVmFragment = { value?: string | null, name?: string | null };

export type CollaboratorVmFragment = { name?: string | null, chapa?: string | null, cpf?: string | null, photo?: string | null };

export type AuthenticateMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUri: Scalars['String'];
}>;


export type AuthenticateMutation = { authenticate?: { name?: string | null, email?: string | null, photo?: string | null, companyName?: string | null, refreshToken?: string | null, chapaDeliveryMan?: string | null, authorizationToken?: string | null } | null };

export type OrderInternalMaintenanceQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderInternalMaintenanceQuery = { orderInternalMaintenance?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type DropDownDefinitionProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type DropDownDefinitionProjectQuery = { dropDownDefinitionProject?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type DropDownElementPepQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type DropDownElementPepQuery = { dropDownElementPep?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type DropDownDiagramNetworkQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type DropDownDiagramNetworkQuery = { dropDownDiagramNetwork?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type DropDownOperationQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type DropDownOperationQuery = { dropDownOperation?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type DropDownCostCenterQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type DropDownCostCenterQuery = { dropDownCostCenter?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type OrderInternalQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type OrderInternalQuery = { orderInternal?: Array<{ value?: string | null, name?: string | null } | null> | null };

export type ReadQrCodeQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type ReadQrCodeQuery = { readQrCode?: { name?: string | null, chapa?: string | null, cpf?: string | null, photo?: string | null } | null };

export type FindReserveOrderQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
}>;


export type FindReserveOrderQuery = { findReserveOrder?: Array<{ code?: string | null, description?: string | null, type?: string | null, deposit?: string | null, shelf?: Array<{ position?: string | null, total: number, validate?: string | null, quantity: number, batch?: string | null, centerDistribution?: string | null, deposit?: string | null } | null> | null } | null> | null };

export type LaunchMemorizedFindQueryVariables = Exact<{
  input?: InputMaybe<Input_LaunchMemorizedRequestVm>;
}>;


export type LaunchMemorizedFindQuery = { launchMemorizedFind?: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id?: string | null, deliveryman?: string | null, type?: string | null, requester?: string | null, date?: string | null, action?: string | null, quantity: number } | null> | null } | null };

export type LaunchMemorizedCountQueryVariables = Exact<{ [key: string]: never; }>;


export type LaunchMemorizedCountQuery = { launchMemorizedCount?: { total: number } | null };

export type FindDeliverymanQueryVariables = Exact<{ [key: string]: never; }>;


export type FindDeliverymanQuery = { findDeliveryman?: Array<{ chapa?: string | null, name?: string | null, photo?: string | null } | null> | null };

export type MaterialFindQueryVariables = Exact<{
  input?: InputMaybe<Input_MaterialRequestVm>;
}>;


export type MaterialFindQuery = { materialFind?: Array<{ code?: string | null, description?: string | null } | null> | null };

export type MaterialResumeByCodeQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type MaterialResumeByCodeQuery = { materialResumeByCode?: { code?: string | null, description?: string | null, type?: string | null, deposit?: string | null, shelf?: Array<{ position?: string | null, total: number, validate?: string | null, quantity: number, batch?: string | null, centerDistribution?: string | null, deposit?: string | null } | null> | null } | null };

export type SaveRequesterMutationVariables = Exact<{
  input?: InputMaybe<Input_RequestVm>;
}>;


export type SaveRequesterMutation = { saveRequester?: { statusCode: number, message?: string | null } | null };

export type StockFindQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type StockFindQuery = { stockFind?: Array<{ id?: string | null, code?: string | null, description?: string | null, shelf?: Array<{ position?: string | null, validate?: string | null, quantity?: string | null, batch?: string | null, unit?: string | null, centerDistribution?: string | null, deposit?: string | null } | null> | null } | null> | null };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  statusCode
  message
}
    `;
export const TokenVmFragmentDoc = gql`
    fragment TokenVm on TokenVm {
  authorizationToken
  refreshToken
  name
  photo
  email
  companyName
  chapaDeliveryMan
}
    `;
export const ShelfFragmentDoc = gql`
    fragment Shelf on Shelf {
  position
  validate
  quantity
  batch
  unit
  centerDistribution
  deposit
}
    `;
export const StockResponseVmFragmentDoc = gql`
    fragment StockResponseVM on StockResponseVm {
  id
  code
  description
  shelf {
    ...Shelf
  }
}
    ${ShelfFragmentDoc}`;
export const LaunchMemorizedVmFragmentDoc = gql`
    fragment LaunchMemorizedVm on LaunchMemorizedVm {
  id
  deliveryman
  type
  requester
  date
  action
  quantity
}
    `;
export const LaunchMemorizedPaginatorVmFragmentDoc = gql`
    fragment LaunchMemorizedPaginatorVm on LaunchMemorizedPaginatorVm {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...LaunchMemorizedVm
  }
}
    ${LaunchMemorizedVmFragmentDoc}`;
export const MaterialResponseVmFragmentDoc = gql`
    fragment MaterialResponseVm on MaterialResponseVm {
  code
  description
}
    `;
export const MaterialShelfFragmentDoc = gql`
    fragment MaterialShelf on MaterialShelf {
  position
  total
  validate
  quantity
  batch
  centerDistribution
  deposit
}
    `;
export const MaterialResumeVmFragmentDoc = gql`
    fragment MaterialResumeVm on MaterialResumeVm {
  code
  description
  type
  deposit
  shelf {
    ...MaterialShelf
  }
}
    ${MaterialShelfFragmentDoc}`;
export const DropDownVmFragmentDoc = gql`
    fragment DropDownVm on DropDownVm {
  value
  name
}
    `;
export const CollaboratorVmFragmentDoc = gql`
    fragment CollaboratorVm on CollaboratorVm {
  name
  chapa
  cpf
  photo
}
    `;
export const AuthenticateDocument = gql`
    mutation Authenticate($code: String!, $redirectUri: String!) {
  authenticate(input: {code: $code, redirectUri: $redirectUri}) {
    name
    email
    photo
    companyName
    refreshToken
    chapaDeliveryMan
    authorizationToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticateGQL extends Apollo.Mutation<AuthenticateMutation, AuthenticateMutationVariables> {
    document = AuthenticateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderInternalMaintenanceDocument = gql`
    query OrderInternalMaintenance {
  orderInternalMaintenance {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderInternalMaintenanceGQL extends Apollo.Query<OrderInternalMaintenanceQuery, OrderInternalMaintenanceQueryVariables> {
    document = OrderInternalMaintenanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DropDownDefinitionProjectDocument = gql`
    query DropDownDefinitionProject {
  dropDownDefinitionProject {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DropDownDefinitionProjectGQL extends Apollo.Query<DropDownDefinitionProjectQuery, DropDownDefinitionProjectQueryVariables> {
    document = DropDownDefinitionProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DropDownElementPepDocument = gql`
    query DropDownElementPep($input: String) {
  dropDownElementPep(projectId: $input) {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DropDownElementPepGQL extends Apollo.Query<DropDownElementPepQuery, DropDownElementPepQueryVariables> {
    document = DropDownElementPepDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DropDownDiagramNetworkDocument = gql`
    query DropDownDiagramNetwork($input: String) {
  dropDownDiagramNetwork(pepId: $input) {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DropDownDiagramNetworkGQL extends Apollo.Query<DropDownDiagramNetworkQuery, DropDownDiagramNetworkQueryVariables> {
    document = DropDownDiagramNetworkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DropDownOperationDocument = gql`
    query DropDownOperation($input: String) {
  dropDownOperation(diagramId: $input) {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DropDownOperationGQL extends Apollo.Query<DropDownOperationQuery, DropDownOperationQueryVariables> {
    document = DropDownOperationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DropDownCostCenterDocument = gql`
    query DropDownCostCenter($input: String) {
  dropDownCostCenter(keyword: $input) {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DropDownCostCenterGQL extends Apollo.Query<DropDownCostCenterQuery, DropDownCostCenterQueryVariables> {
    document = DropDownCostCenterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderInternalDocument = gql`
    query OrderInternal($input: String) {
  orderInternal(costCenterId: $input) {
    ...DropDownVm
  }
}
    ${DropDownVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderInternalGQL extends Apollo.Query<OrderInternalQuery, OrderInternalQueryVariables> {
    document = OrderInternalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReadQrCodeDocument = gql`
    query ReadQrCode($input: String) {
  readQrCode(code: $input) {
    ...CollaboratorVm
  }
}
    ${CollaboratorVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReadQrCodeGQL extends Apollo.Query<ReadQrCodeQuery, ReadQrCodeQueryVariables> {
    document = ReadQrCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindReserveOrderDocument = gql`
    query FindReserveOrder($code: String, $type: String) {
  findReserveOrder(code: $code, type: $type) {
    ...MaterialResumeVm
  }
}
    ${MaterialResumeVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindReserveOrderGQL extends Apollo.Query<FindReserveOrderQuery, FindReserveOrderQueryVariables> {
    document = FindReserveOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LaunchMemorizedFindDocument = gql`
    query LaunchMemorizedFind($input: Input_LaunchMemorizedRequestVm) {
  launchMemorizedFind(input: $input) {
    ...LaunchMemorizedPaginatorVm
  }
}
    ${LaunchMemorizedPaginatorVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LaunchMemorizedFindGQL extends Apollo.Query<LaunchMemorizedFindQuery, LaunchMemorizedFindQueryVariables> {
    document = LaunchMemorizedFindDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LaunchMemorizedCountDocument = gql`
    query LaunchMemorizedCount {
  launchMemorizedCount {
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LaunchMemorizedCountGQL extends Apollo.Query<LaunchMemorizedCountQuery, LaunchMemorizedCountQueryVariables> {
    document = LaunchMemorizedCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindDeliverymanDocument = gql`
    query FindDeliveryman {
  findDeliveryman {
    chapa
    name
    photo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindDeliverymanGQL extends Apollo.Query<FindDeliverymanQuery, FindDeliverymanQueryVariables> {
    document = FindDeliverymanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialFindDocument = gql`
    query MaterialFind($input: Input_MaterialRequestVm) {
  materialFind(input: $input) {
    ...MaterialResponseVm
  }
}
    ${MaterialResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialFindGQL extends Apollo.Query<MaterialFindQuery, MaterialFindQueryVariables> {
    document = MaterialFindDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaterialResumeByCodeDocument = gql`
    query MaterialResumeByCode($input: String) {
  materialResumeByCode(code: $input) {
    ...MaterialResumeVm
  }
}
    ${MaterialResumeVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaterialResumeByCodeGQL extends Apollo.Query<MaterialResumeByCodeQuery, MaterialResumeByCodeQueryVariables> {
    document = MaterialResumeByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveRequesterDocument = gql`
    mutation SaveRequester($input: Input_RequestVm) {
  saveRequester(input: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveRequesterGQL extends Apollo.Mutation<SaveRequesterMutation, SaveRequesterMutationVariables> {
    document = SaveRequesterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockFindDocument = gql`
    query StockFind($input: String) {
  stockFind(keyword: $input) {
    ...StockResponseVM
  }
}
    ${StockResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StockFindGQL extends Apollo.Query<StockFindQuery, StockFindQueryVariables> {
    document = StockFindDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }