import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BaseComponent} from "./core/base.component";
import {Router} from "@angular/router";
import {MomentConfigService} from "./core/services/moment-config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  language = super.accessLanguage.length === 0 ? 'pt-BR' : super.accessLanguage;

  constructor(public router: Router,
              public translate: TranslateService,
              private momentConfigService: MomentConfigService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    super.handlerCultureLanguage(this.language);
    setTimeout(() => {
      this.toggleLoader(false);
    }, 700);
  }

}
