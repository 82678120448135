import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "./core/core.module";
import {GraphQLModule} from './graphql.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from "@angular/forms";
import {AuthInterceptor} from "./core/auth/auth.interceptor";
import {LanguageModule} from "./modules/language/language.module";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {httpTranslateLoader} from "./core/translate/translate-loader-factory";
import {CommonModule, registerLocaleData} from "@angular/common";
import {AngularSvgIconModule} from "angular-svg-icon";
import {AuthenticateModule} from "./modules/authenticate/authenticate.module";
import {DataService} from "./core/data.service";

import localePt from '@angular/common/locales/pt';
import {MomentConfigService} from "./core/services/moment-config.service";
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    CoreModule,
    LanguageModule,
    AuthenticateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CoreModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    },
    MomentConfigService,
    TranslateService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

